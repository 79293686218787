
import { defineComponent, ref } from '@vue/composition-api'

import { mdiEye, mdiEyeOff } from '@mdi/js'
import { isRequired, passwordsMatch, isPasswordStrong, isPasswordValid } from '@/utils/validation'

export default defineComponent({
  name: 'CommonNewPasswordInput',
  props: {
    value: {
      type: String,
      required: true,
    },
    horizontal: {
      type: Boolean,
      required: false,
    },
    isRequiredProp: {
      type: Boolean,
      required: false,
    },
  },
  setup: (props) => {
    const password = ref(props.value)
    const repeatPassword = ref('')
    const showPassword = ref(false)

    return {
      icons: {
        mdiEye,
        mdiEyeOff,
      },
      password,
      repeatPassword,
      showPassword,
      isRequired,
      passwordsMatch,
      isPasswordStrong,
      isPasswordValid,
    }
  },
})
