var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h4', {
    staticClass: "text-h4"
  }, [_vm._v(_vm._s(_vm.$t('users.edit.userdata')))])])], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('form.field.email'),
      "rules": [_vm.isRequired(_vm.email, _vm.$t('form.field.email')), _vm.isEmail(_vm.email, _vm.$t('form.field.email'))],
      "type": "email",
      "prepend-inner-icon": _vm.icons.mdiEmail,
      "outlined": ""
    },
    on: {
      "input": _vm.updateUser
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('form.field.firstName'),
      "rules": [_vm.isRequired(_vm.firstName, _vm.$t('form.field.firstName'))],
      "outlined": ""
    },
    on: {
      "input": _vm.updateUser
    },
    model: {
      value: _vm.firstName,
      callback: function callback($$v) {
        _vm.firstName = $$v;
      },
      expression: "firstName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('form.field.lastName'),
      "rules": [_vm.isRequired(_vm.lastName, _vm.$t('form.field.lastName'))],
      "outlined": ""
    },
    on: {
      "input": _vm.updateUser
    },
    model: {
      value: _vm.lastName,
      callback: function callback($$v) {
        _vm.lastName = $$v;
      },
      expression: "lastName"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('form.field.phone'),
      "type": "tel",
      "prepend-inner-icon": _vm.icons.mdiPhone,
      "outlined": ""
    },
    on: {
      "input": _vm.updateUser
    },
    model: {
      value: _vm.phone,
      callback: function callback($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('form.field.mobile'),
      "type": "tel",
      "prepend-inner-icon": _vm.icons.mdiCellphone,
      "outlined": ""
    },
    on: {
      "input": _vm.updateUser
    },
    model: {
      value: _vm.mobile,
      callback: function callback($$v) {
        _vm.mobile = $$v;
      },
      expression: "mobile"
    }
  })], 1)], 1), _vm.isUserLocal() ? _c('v-divider', {
    staticClass: "my-4"
  }) : _vm._e(), _c('v-row', [_vm.isUserLocal() ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h4', {
    staticClass: "text-h4"
  }, [_vm._v(_vm._s(_vm.$t('users.edit.userpassword')))])]) : _vm._e()], 1), _c('v-row', [_vm.isCreate ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    attrs: {
      "type": "info"
    }
  }, [_vm._v(_vm._s(_vm.$t('users.edit.password.prompt')))])], 1) : _vm._e()], 1), _vm.isUserLocal() ? _c('CommonNewPasswordInput', {
    attrs: {
      "horizontal": _vm.isMdAndUp ? true : false
    },
    on: {
      "input": _vm.updateUser
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }) : _vm._e(), _c('v-divider', {
    staticClass: "my-4"
  }), _c('v-row', [_c('v-col', [_c('h4', {
    staticClass: "text-h4"
  }, [_vm._v(_vm._s(_vm.$t('users.edit.usersettings')))])])], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    staticClass: "d-flex justify-space-between"
  }, [_c('v-switch', {
    attrs: {
      "label": _vm.enabled ? _vm.$t('form.field.enabled') : _vm.$t('form.field.disabled')
    },
    on: {
      "change": _vm.updateUser
    },
    model: {
      value: _vm.enabled,
      callback: function callback($$v) {
        _vm.enabled = $$v;
      },
      expression: "enabled"
    }
  }), _c('v-switch', {
    attrs: {
      "label": _vm.loginDisabled ? _vm.$t('form.field.loginDisabled') : _vm.$t('form.field.loginEnabled'),
      "true-value": false,
      "false-value": true
    },
    on: {
      "change": _vm.updateUser
    },
    model: {
      value: _vm.loginDisabled,
      callback: function callback($$v) {
        _vm.loginDisabled = $$v;
      },
      expression: "loginDisabled"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('CommonAutocomplete', {
    attrs: {
      "label": _vm.$tc('form.field.locale', 1),
      "rules": [_vm.isRequired(_vm.locale, _vm.$tc('form.field.locale', 1))],
      "items": _vm.implementedLocales,
      "outlined": ""
    },
    on: {
      "input": _vm.updateUser
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm.resolveCountry(item) !== '' ? _c('CommonFlagIcon', {
          staticClass: "mr-2",
          attrs: {
            "locale": _vm.resolveCountry(item)
          }
        }) : _vm._e(), _vm.resolveCountry(item) === '' ? _c('v-icon', {
          staticClass: "mr-2"
        }, [_vm._v(_vm._s(_vm.icons.mdiEarth))]) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t("locale.".concat(item))) + " ")];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item,
            on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({}, 'v-list-item', attrs, false), on), [_c('v-list-item-icon', {
          staticClass: "mr-2"
        }, [_vm.resolveCountry(item) !== '' ? _c('CommonFlagIcon', {
          attrs: {
            "locale": _vm.resolveCountry(item)
          }
        }) : _vm._e(), _vm.resolveCountry(item) === '' ? _c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiEarth))]) : _vm._e()], 1), _vm._v(" " + _vm._s(_vm.$t("locale.".concat(item))) + " ")], 1)];
      }
    }]),
    model: {
      value: _vm.locale,
      callback: function callback($$v) {
        _vm.locale = $$v;
      },
      expression: "locale"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('CommonAutocomplete', {
    attrs: {
      "label": _vm.$t('form.field.role'),
      "items": _vm.roleOptions,
      "rules": [_vm.isRequired(_vm.roles, _vm.$t('form.field.role'))],
      "item-text": "displayName",
      "item-value": "name",
      "outlined": "",
      "multiple": ""
    },
    on: {
      "input": _vm.updateUser
    },
    model: {
      value: _vm.roles,
      callback: function callback($$v) {
        _vm.roles = $$v;
      },
      expression: "roles"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }