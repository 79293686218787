var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": _vm.horizontal ? 6 : 12
    }
  }, [_c('v-text-field', {
    attrs: {
      "autocomplete": "new-password",
      "type": _vm.showPassword ? 'text' : 'password',
      "rules": _vm.isRequiredProp ? [_vm.isRequired(_vm.password, _vm.$t('form.field.newPassword')), _vm.isPasswordStrong(_vm.password)] : !!_vm.password ? [_vm.$t(_vm.isPasswordValid(_vm.password)), _vm.isPasswordStrong(_vm.password)] : [],
      "label": _vm.$t('form.field.newPassword'),
      "hint": _vm.$t('form.hint.newPassword'),
      "append-icon": _vm.showPassword ? _vm.icons.mdiEye : _vm.icons.mdiEyeOff,
      "outlined": ""
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.showPassword = !_vm.showPassword;
      },
      "input": function input($event) {
        return _vm.$emit('input', $event);
      }
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": _vm.horizontal ? 6 : 12
    }
  }, [_c('v-text-field', {
    attrs: {
      "autocomplete": "new-password",
      "type": _vm.showPassword ? 'text' : 'password',
      "label": _vm.$t('form.field.newPasswordRepeat'),
      "hint": _vm.$t('form.hint.newPasswordRepeat'),
      "rules": _vm.isRequiredProp ? [_vm.isRequired(_vm.repeatPassword, _vm.$t('form.field.newPasswordRepeat')), _vm.passwordsMatch(_vm.value, _vm.repeatPassword)] : !!_vm.password ? [_vm.passwordsMatch(_vm.password, _vm.repeatPassword)] : [],
      "append-icon": _vm.showPassword ? _vm.icons.mdiEye : _vm.icons.mdiEyeOff,
      "outlined": ""
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.showPassword = !_vm.showPassword;
      }
    },
    model: {
      value: _vm.repeatPassword,
      callback: function callback($$v) {
        _vm.repeatPassword = $$v;
      },
      expression: "repeatPassword"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }