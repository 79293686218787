
import { computed, defineComponent, PropType, ComputedRef, reactive, toRefs } from '@vue/composition-api'

import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { tf, resolveCountry } from '@/plugins/i18n'
import { UserRoleDisplay } from '@/api/types/role'
import { useGetRoles } from '@/api/role'
import { UserUpdate } from '@/api/types/user'

import { mdiEmail, mdiPhone, mdiCellphone, mdiEarth, mdiKey } from '@mdi/js'
import { isRequired, isEmail } from '@/utils/validation'

import CommonFlagIcon from '@/components/common/CommonFlagIcon.vue'
import CommonNewPasswordInput from '@/components/common/CommonNewPasswordInput.vue'

export default defineComponent({
  name: 'UserForm',
  components: {
    CommonFlagIcon,
    CommonNewPasswordInput,
    CommonAutocomplete,
  },
  props: {
    value: {
      type: Object as PropType<UserUpdate>,
      required: true,
    },
    source: {
      type: String,
      required: false,
      default: 'local',
    },
    isCreate: {
      type: Boolean,
      required: false,
    },
  },
  setup: (props, { root, emit }) => {
    const { exec: getRoles, data: roleOptions } = useGetRoles()
    getRoles()

    const translatedRoleOptions: ComputedRef<UserRoleDisplay[]> = computed(() =>
      roleOptions.value.map((r) => Object.assign(r, { displayName: tf(`roles.names.${r.name}`, r.name).toString() }))
    )

    const emptyUser: UserUpdate = {
      username: '',
      email: '',
      firstName: '',
      lastName: '',
      locale: '',
      phone: '',
      mobile: '',
      enabled: false,
      roles: undefined,
      password: '',
      loginDisabled: false,
    }

    const user = reactive({ ...emptyUser, ...props.value })

    const updateUser = () => {
      emit('input', user)
    }

    const implementedLocales = computed<string[]>(() => root.$store.state.locale.implementedLocales)

    const isMdAndUp = computed(() => root.$vuetify.breakpoint.mdAndUp)

    const isUserLocal = () => {
      return props.source === 'local'
    }

    return {
      icons: { mdiEmail, mdiPhone, mdiCellphone, mdiEarth, mdiKey },
      ...toRefs(user),
      updateUser,
      isRequired,
      isEmail,
      roleOptions: translatedRoleOptions,
      implementedLocales,
      isMdAndUp,
      isUserLocal,
      resolveCountry,
    }
  },
})
